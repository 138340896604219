import classNames from 'classnames'
import React from 'react'

import './button.scss'
import AnyLink from '../any-link'
import Icon from '../icon'

import type { Props } from './button.types'

const Button = (props: Props) => {
  const {
    htmlType = 'button',
    type = 'primary',
    glyph,
    glyphSide = 'right',
    ...otherProps
  } = props
  const types = Array.isArray(type) ? type : [type]
  const className = classNames(props.className, 'amp-button', ...types)
  const content = (
    <span className="inner">
      {glyph && glyphSide === 'left' && (
        <Icon glyph={glyph} className="left-glyph" />
      )}
      {props.type === 'previous' && (
        <Icon glyph="chevron-left" className="left-glyph" />
      )}
      <span className="button-content">{props.children}</span>
      {props.type === 'progress' && (
        <Icon glyph="chevron-right" className="right-glyph" />
      )}
      {props.type === 'progress-alt' && (
        <Icon glyph="chevron-down" className="right-glyph" />
      )}
      {glyph && glyphSide === 'right' && (
        <Icon
          glyph={glyph}
          className={classNames({
            'right-glyph': type !== 'icon',
          })}
        />
      )}
    </span>
  )

  if (props.to && !props.disabled) {
    return (
      <AnyLink {...otherProps} className={className} role="button">
        {content}
      </AnyLink>
    )
  } else {
    return (
      <button
        data-testid="button"
        {...otherProps}
        type={htmlType}
        className={className}
      >
        {content}
      </button>
    )
  }
}

export default Button
