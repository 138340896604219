import React from 'react'

import { isSpecialURI, isNormalURI, stripPrefixFromURI } from '~/utils/uri'

import type { ReactNode } from 'react'

export type Props = {
  children: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  role?: string
  target?: string
  to?: string
}

export default function AnyLink(props: Props) {
  if (LinkComponent == null) return null

  const { children, ...otherProps } = props
  const { to } = otherProps

  if (typeof to === 'string' && isNormalURI(to)) {
    if (!isSpecialURI(to) && !props.target) {
      otherProps.target = '_blank'
    }

    return (
      <a href={stripPrefixFromURI(to)} {...otherProps}>
        {children}
      </a>
    )
  } else {
    return (
      <LinkComponent role="link" {...otherProps}>
        {children}
      </LinkComponent>
    )
  }
}

let LinkComponent:
  | (string | ((...args: Array<any>) => any))
  | null
  | undefined = null

export function setLinkComponent(component: any) {
  LinkComponent = component
}
